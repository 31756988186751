import { render, staticRenderFns } from "./RadioGrade.vue?vue&type=template&id=14cf94a0&scoped=true&"
import script from "./RadioGrade.vue?vue&type=script&lang=js&"
export * from "./RadioGrade.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14cf94a0",
  null
  
)

export default component.exports