import { getError } from "@/plugins/helpers";
import ModuleService from "@/services/ModuleService";
export const namespaced = true;

export const state = {
  dateData: {
    moduleA: {
      sectionA: false,
    },
  },
  loading: false,
  error: null,
};

export const mutations = {
  SET_MODULES(state, dateData) {
    state.dateData = dateData;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  SET_DATA(state, dateData) {
    state.dateData = dateData;
  },
};

export const actions = {
  async getDateData({ commit }, payload) {
    commit("SET_LOADING", true);
    try {
      const response = await ModuleService.getDate(payload);
      commit("SET_DATA", response.data.data);
      commit("SET_LOADING", false);
      commit("SET_ERROR", response);
    } catch (error) {
      commit("SET_LOADING", false);
      commit("SET_ERROR", getError(error));
    }
  },
};
export const getters = {
  dateData: (state) => {
    return state.dateData;
  },
};
